import { React } from 'react';
import HoldingPage from './components/HoldingPage';

function App() {
  return (
    <div className="App">
      <HoldingPage />
    </div>
  );
}

export default App;
